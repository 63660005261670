<template>
  <div class="mb-2">
    <label class="block text-sm font-medium leading-5 text-gray-700">{{
      label
    }}</label>
    <wysiwyg
      class="
        bg-white
        shadow
        rounded
        border-2 border-red-500
        overflow-hidden
        mt-1
      "
      v-model="innerValue"
      placeholder="Ingresa un texto"
    />
    <div class="text-xs text-gray-600">{{ description }}</div>
  </div>
</template>

<script>
export default {
  props: ["description", "label", "placeholder", "value"],
  data() {
    return {
      innerValue: null,
      customToolbar: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          { color: [] },
          { background: [] },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
    };
  },
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
  },
  mounted() {
    this.innerValue = this.value;
  },
};
</script>

<style lang="scss">
.editr {
  border-color: #a0aec0;
}
</style>

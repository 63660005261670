<template>
  <div class="flex">
    <a
      :href="connectUrl"
      target="_blank"
      class="flex flex-col justify-center p-2 bg-white border border-blue-500 rounded cursor-pointer hover:bg-blue-100"
    >
      <div class="flex justify-center text-center">
        <img class="h-10" src="@/assets/mercado-pago.png" />
      </div>
      <div class="mt-2 text-xs font-medium text-gray-600">
        Hace click aquí para enlazar tu cuenta de MercadoPago
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {},
  props: {
    country: {
      type: String,
      default: () => {
        return "AR";
      },
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // APP_ID: 6814267314550310,
      url: "https://admin.orsai.org/settings/authorize",
      // url: "http://localhost:1024/settings/authorize",
    };
  },
  async created() {},
  computed: {
    APP_ID() {
      // Hernan Casciari
      return 529652580679760;

      // if (!this.type) return 8230697127795438;
      // else return 8230697127795438; // Lizy Tagliani
    },
    ...mapGetters("auth", { user: "user" }),
    eventId() {
      return this.$route.params.id;
    },
    userId() {
      return this.user._id;
    },
    returnUrl() {
      return this.url;
      // return encodeURIComponent(this.url + "?" + this.query);
    },
    query() {
      const obj = { eventId: this.eventId };
      var str = [];
      for (var p in obj) str.push(p + "=" + obj[p]);
      return str.join("&");
    },
    connectUrl() {
      if (this.country === "AR")
        return `https://auth.mercadopago.com.ar/authorization?client_id=${this.APP_ID}&response_type=code&platform_id=mp&redirect_uri=${this.returnUrl}`;
      else
        return `https://auth.mercadopago.com.uy/authorization?client_id=${this.APP_ID}&response_type=code&platform_id=mp&redirect_uri=${this.returnUrl}`;
    },
  },
  methods: {},
};
</script>

<template>
  <div>
    <w-top-bar>Configuración</w-top-bar>
    <div class="bg-white">
      <nav class="flex flex-col sm:flex-row">
        <button
          @click="tab = null"
          :class="{
            'text-blue-500 border-b-2 font-medium border-blue-500': !tab,
          }"
          class="block px-6 py-4 text-gray-600 hover:text-blue-500 focus:outline-none"
        >
          General</button
        ><button
          @click="tab = 'advanced'"
          :class="{
            'text-blue-500 border-b-2 font-medium border-blue-500': tab == 'advanced',
          }"
          class="block px-6 py-4 text-gray-600 hover:text-blue-500 focus:outline-none"
        >
          Avanzada
        </button>
      </nav>
    </div>
    <div v-if="!tab && item" class="h-full p-5 bg-gray-200">
      <FeathersVuexFormWrapper :item="item" watch>
        <!-- <template v-slot="{ clone, save, reset, remove }"> -->
        <template v-slot="{ clone, save }">
          <!-- <div class="w-full py-2 mb-2 border-b border-gray-400">Config</div> -->
          <div>
            <div>
              <w-wysiwyg label="Texto de horarios" v-model="clone.hours" />
            </div>
            <div>
              <w-html label="Bloque HTML de Cabecera" v-model="clone.content" />
            </div>

            <div class="mt-5">
              <w-switch label="Público" v-model="clone.isPublic"></w-switch>
              <div class="text-sm text-gray-500 md:text-normal">
                Todos los visitantes, pueden ver el bloque.
              </div>
            </div>

            <w-html
              class="mt-5"
              description="Para ingresar código CSS y/o JS, utilizar los tags <script> y <style> respectivamente."
              label="Píe de página HTML (CSS/JS)"
              v-model="clone.extraHtml"
            />

            <div class="flex justify-end py-2">
              <a
                v-if="!saving && !saved"
                target="_blank"
                href="https://tienda.orsai.org?preview=1"
                class="flex items-center px-4 py-2 mr-2 text-sm font-bold text-white transition duration-150 ease-in-out rounded bg-marine-500 hover:bg-marine-700"
              >
                Previsualizar
              </a>
              <div v-if="saved" class="flex items-center text-sm font-medium text-green-600">
                <div>Cambios guardados</div>
                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-green-600 transition duration-150 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div v-if="saving" class="flex items-center text-sm font-medium text-gray-600">
                <div>Guardando cambios</div>

                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-gray-600 transition duration-150 ease-in-out animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </div>
              <button
                @click="doSave(save)"
                class="flex items-center px-4 py-2 text-sm font-bold text-white transition duration-150 ease-in-out bg-green-500 rounded hover:bg-green-700"
              >
                Guardar
              </button>
            </div>
          </div>
        </template></FeathersVuexFormWrapper
      >
    </div>
    <div v-if="tab === 'advanced'" class="h-full p-5 bg-gray-200">
      <div>
        <div class="w-full py-2 mb-2 border-b border-gray-400">
          Integración con Mercado Pago (ARG)
        </div>
        <div class="flex items-center" v-if="linked">
          <svg
            class="w-8 h-8 my-5 text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div class="ml-2 text-green-600">
            <div>Integración activa</div>
            <div class="text-xs">
              {{ $moment(tokens[0].createdAt).format("DD/MM/YYYY HH:mm[hs]") }}
            </div>
          </div>
        </div>
        <w-mercado-pago v-else />
      </div>
      <div>
        <div class="w-full py-2 mb-2 border-b border-gray-400">
          Integración con Mercado Pago (URU)
        </div>
        <div class="flex items-center" v-if="linkedURU">
          <svg
            class="w-8 h-8 my-5 text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div class="ml-2 text-green-600">
            <div>Integración activa</div>
            <div class="text-xs">
              {{ $moment(tokens[0].createdAt).format("DD/MM/YYYY HH:mm[hs]") }}
            </div>
          </div>
        </div>
        <w-mercado-pago country="UY" v-else />
      </div>
      <div>
        <div class="w-full py-2 mb-2 border-b border-gray-400">
          Integración con Mercado Pago (Lizy Tagliani)
        </div>
        <div class="flex items-center" v-if="tokenTagliani">
          <svg
            class="w-8 h-8 my-5 text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div class="ml-2 text-green-600">
            <div>Integración activa</div>
            <div class="text-xs">
              {{ $moment(tokens[0].createdAt).format("DD/MM/YYYY HH:mm[hs]") }}
            </div>
          </div>
        </div>
        <w-mercado-pago country="AR" type="lizy-tagliani" v-else />
      </div>
      <div>
        <div class="w-full py-2 mb-2 border-b border-gray-400">
          Integración con Mercado Pago (Hernán Casciari)
        </div>
        <div class="flex items-center" v-if="tokenCasciari">
          <svg
            class="w-8 h-8 my-5 text-green-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div class="ml-2 text-green-600">
            <div>Integración activa</div>
            <div class="text-xs">
              {{ $moment(tokens[0].createdAt).format("DD/MM/YYYY HH:mm[hs]") }}
            </div>
          </div>
        </div>
        <w-mercado-pago country="AR" type="hernan-casciari" v-else />
      </div>
      <div class="mt-8">
        <div class="w-full py-2 mb-2 border-b border-gray-400">Sincronización con sitio</div>
        <label for="input" class="block text-sm font-medium leading-5 text-gray-700"
          >Actualizar Base de Datos</label
        >
        <button
          class="flex items-center justify-center w-40 rounded-none btn btn-blue"
          @click="update()"
        >
          <svg
            v-if="busy"
            class="w-4 h-4 mr-1 -ml-1 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>

          <span>Actualizar</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { makeFindMixin, models } from "feathers-vuex";
import { FeathersVuexFormWrapper } from "feathers-vuex";
export default {
  data() {
    return {
      busy: false,
      tab: null,
      header: null,
      saving: false,
      saved: false,
    };
  },
  mixins: [makeFindMixin({ service: "tokens" })],
  computed: {
    tokenTagliani() {
      return this.tokens.find((t) => t.owner === "lizy-tagliani");
    },
    tokenCasciari() {
      return this.tokens.find((t) => t.owner === "hernan-casciari");
    },
    tokensParams() {
      return {
        query: {
          type: "mercadopago",
        },
      };
    },
    linked() {
      return this.tokens.find((e) => {
        return e.country === "AR";
      });
    },
    linkedURU() {
      return this.tokens.find((e) => {
        return e.country === "UY";
      });
    },
    id() {
      return "6080aec1b36c1f0a379ea742";
    },
    // Returns a new Todo if the route `id` is 'new', or returns an existing Todo.
    item() {
      const { Variables } = this.$FeathersVuex.api;

      return this.id === "new" ? new Variables() : Variables.getFromStore(this.id);
      // return new Variables();
    },
  },
  components: {
    FeathersVuexFormWrapper,
  },
  watch: {
    id: {
      handler(val) {
        // Early return if the route `:id` is 'new'
        if (!val) {
          return;
        }
        const { Variables } = this.$FeathersVuex.api;
        const existingRecord = Variables.getFromStore(val);

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Variables.get(val);
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true,
    },
  },
  methods: {
    async update() {
      this.busy = true;
      const { Update } = models.api;
      let result = await new Update({}).save();
      console.log(result);
      this.busy = false;
    },
    async doSave(save) {
      this.saved = false;
      this.saving = true;
      let saved = await save();
      this.saving = false;
      this.saved = true;
      const self = this;
      setTimeout(() => {
        self.saved = false;
      }, 3000);
      console.log(saved);
    },
  },
};
</script>

<style lang="scss" scoped></style>
